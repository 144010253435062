import { useEffect } from 'react'
import { Container } from './styles'

interface LoadingProps {
    isLoading: boolean;
}

export function Loading({ isLoading }: LoadingProps) {

    useEffect(() => {


    }, [isLoading]);

    if (isLoading) {
        return (
            <Container>
                <svg>
                    <circle cx="70" cy="70" r="70"></circle>
                </svg>
            </Container>

        )
    } else {
        return <></>
    }

}